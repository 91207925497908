<template>
	<div>
		<div class="container">
			<el-table
				:data="tableData"
				border
				class="table"
				ref="multipleTable"
				header-cell-class-name="table-header"
				:header-cell-style="{ 'text-align': 'center' }"
				:row-class-name="tableRowClassName"
			>
				<el-table-column prop="title" label="名称" align="center"></el-table-column>
				<el-table-column prop="url" label="地址" align="center">
					<template #default="scope">
						<span style="cursor: pointer;" @click="toURL(scope.row.url)">{{ scope.row.url }}</span>
					</template>
				</el-table-column>
			</el-table>
			<el-dialog v-model="urlVisible" width="820px"><iframe :src="iframeURL" frameborder="0" width="800" height="600"></iframe></el-dialog>
		</div>
	</div>
</template>

<script>
import { getFindOperationManual } from '../api/operationManual.js';

export default {
	name: 'serial',
	data() {
		return {
			tableData: [],
			urlVisible: false,
			iframeURL: ''
		};
	},
	created() {
		this.getData();
	},
	methods: {
		tableRowClassName({ rowIndex }) {
			if ((rowIndex + 1) % 2 === 0) {
				return 'oddRow';
			}
			return 'evenRow';
		},
		getData() {
			getFindOperationManual().then(res => {
				if (res.code === 200) {
					this.tableData = res.data;
				} else {
					this.$message.error(res.message);
				}
			});
		},
		toURL(e) {
			this.urlVisible = true;
			this.iframeURL = e;
		}
	}
};
</script>

<style scoped>
.handle-box {
	margin-bottom: 20px;
}

/* a:hover{background: #66b1ff} */
.handle-select {
	width: 240px;
}

.table {
	width: 100%;
	font-size: 14px;
}
.red {
	color: #ff0000;
}
.mr10 {
	margin-right: 10px;
}

.mr100 {
	position: absolute;
	right: 100px;
}

.myRight {
	position: absolute;
	right: 10px;
	bottom: 10px;
}

.handle-input {
	width: 200px;
	display: inline-block;
}
</style>
